// yearSlice.js

import { createSlice } from '@reduxjs/toolkit'
import { DataEntryForm } from '../../../lib/types'

interface InitialStateProps {
	selectedYear: string
	dataEntry: {
		data: DataEntryForm | null
		status: string
		date: string
		fileName: string
	}[]
}

const initialState: InitialStateProps = {
	selectedYear: 'Today',
	dataEntry: [],
}
const fakeSlick = createSlice({
	name: 'fake',
	initialState,
	reducers: {
		changeYear: (state, action) => {
			state.selectedYear = action.payload
		},
		uploadData: (state, { payload }) => {
			const newDataEntryItem = {
				data: payload.data,
				date: Date.now().toString(),
				fileName: `Dataset ${Math.floor(Math.random() * 100)}`,
				status: 'Pending',
				user: payload.name,
			}
			state.dataEntry = [...state.dataEntry, newDataEntryItem]
		},
		approveData: (state, { payload }) => {},
		rejectData: (state, { payload }) => {},
	},
})

export const { changeYear, uploadData, approveData, rejectData } = fakeSlick.actions

export default fakeSlick.reducer
