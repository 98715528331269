import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import AllProviders from './components/AllProviders'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { openDB } from 'idb'
import { BackgroundSyncConstants } from './lib/background-sync'
import { triggerBackgroundSync } from './lib/background-sync/triggerBackgroundSync'

if ('Notification' in window) {
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
		}
	})
}

openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION, {
	upgrade(db) {
		db.createObjectStore(BackgroundSyncConstants.AUTH_DATA_STORE_NAME, { autoIncrement: true })
		db.createObjectStore(BackgroundSyncConstants.STATE_DATA_STORE_NAME, { autoIncrement: true })
	},
})

triggerBackgroundSync()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<AllProviders>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</AllProviders>
	</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
