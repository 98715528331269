export const years = [ 'Today', 'Yesterday', 'This Month', 'This Year' ];
function getRandomNumber(min: number, max: number)
{
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const schools = (year: string) =>
{
	return [
		{
			state: 'Adamawa',
			number: year === 'Today' ? 40 : getRandomNumber(4000, 8500),
		},
		{
			state: 'Katsina',
			number: year === 'Today' ? 68 : getRandomNumber(5000, 8500),
		},
		{
			state: 'Oyo',
			number: year === 'Today' ? 88 : getRandomNumber(7000, 8500),
		},
	];
};

export const teachers = (year: string) =>
{
	return [
		{
			state: 'Adamawa',
			number: getRandomNumber(1000, 8500),
		},
		{
			state: 'Katsina',
			number: getRandomNumber(1000, 8500),
		},
		{
			state: 'Oyo',
			number: getRandomNumber(1000, 8500),
		},
	];
};

export const pupils = (year: string) =>
{
	return [
		{
			state: 'Adamawa',
			number: getRandomNumber(600, 3000),
		},
		{
			state: 'Katsina',
			number: getRandomNumber(600, 3000),
		},
		{
			state: 'Oyo',
			number: getRandomNumber(600, 3000),
		},
	];
};

export const overviewTable = (year: any) => [
	{
		state: 'Adamawa',
		Primary: getRandomNumber(600, 3000),
		Secondary: getRandomNumber(600, 3000),
		tertiary: getRandomNumber(600, 3000),
	},
	{
		state: "Oyo",
		Primary: getRandomNumber(600, 3000),
		Secondary: getRandomNumber(600, 3000),
		tertiary: getRandomNumber(600, 3000),
	},
	{
		state: 'Katsina',
		Primary: getRandomNumber(600, 3000),
		Secondary: getRandomNumber(600, 3000),
		tertiary: getRandomNumber(600, 3000),
	},
];


export const teachersToStudentRatio = [
	{
		state: "oyo",
		data: [
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2015
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2016
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2017
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2018
			},
		]
	},
	{
		state: "adamawa",
		data: [
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2015
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2016
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2017
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2018
			},
		]
	},
	{
		state: "katsina",
		data: [
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2015
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2016
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2017
			},
			{
				teacher: getRandomNumber(600, 3000),
				student: getRandomNumber(200, 4000),
				year: 2018
			},
		]
	}


];
export let schoolCount = (year: string) =>
{
	return getRandomNumber(5000, 1900);
};


export const userTable = [
	{
		id: 1,
		firstName: "Ahmed",
		lastName: "Ibrahim",
		email: "ahmed@gmail.come",
		phone: "09067687576",
		level: "Federal",
		role: "Super Admin",
		status: "active"
	},
	{
		id: 2,
		firstName: "Grace",
		lastName: "Okafor",
		email: "grace@gmail.come",
		phone: "09067687576",
		level: "State",
		role: "Admin",
		status: "active"
	},
	{
		id: 3,
		firstName: "Shola",
		lastName: "Debo",
		email: "shola@gmail.come",
		phone: "090543533",
		level: "Federal",
		role: "Supervisor",
		status: "active"
	},

];


export const schoolsInlga = [
	{
		state: "oyo",
		lgas: [
			{
				name: "lga1",
				Secondary: getRandomNumber(100, 1000),
				Primary: getRandomNumber(100, 1000),

			},
			{
				name: "lga1",
				Secondary: getRandomNumber(100, 1000),
				Primary: getRandomNumber(100, 1000),

			},
			{
				name: "lga1",
				Secondary: getRandomNumber(100, 1000),
				Primary: getRandomNumber(100, 1000),

			}
		]

	},
	{
		state: "adamawa",
		lgas: [
			{
				name: "lga1",
				Secondary: 5000,
				Primary: 3000

			},
			{
				name: "lga1",
				Secondary: 2500,
				Primary: 300

			},
			{
				name: "lga1",
				Secondary: 1000,
				Primary: 600

			}
		]

	},
	{
		state: "katsina",
		lgas: [
			{
				name: "lga1",
				Secondary: 5000,
				Primary: 3000

			},
			{
				name: "lga1",
				Secondary: 2500,
				Primary: 300

			},
			{
				name: "lga1",
				Secondary: 1000,
				Primary: 600

			}
		]
	}
];



export const allSchools: any = {
	nadaboPrimarySchool: {
		name: "Nadabo Primary School",
		code: "121011001",
		npaCode: "200010001",
		state: "Katsina",
		localGovernment: "Bayori",
		longitudeN: "11.55486111",
		latitudeE: "7.42202778",
		locationType: "Urban",
		schoolNameType: "Regular",
		sectorType: "Primary"
	},
	ramalanPrimarySchool: {
		name: "Ramalan Primary School Bakori",
		code: "121011002",
		npaCode: "200010002",
		state: "Katsina",
		localGovernment: "Bayori",
		longitudeN: "11.55486333",
		latitudeE: "7.42202778",
		locationType: "Urban",
		schoolNameType: "Regular",
		sectorType: "Primary"
	},
	gwamutsawaPrimarySchool: {
		name: "Gwamutsawa Primary School",
		code: "121011003",
		npaCode: "200010003",
		state: "Katsina",
		localGovernment: "Bayori",
		longitudeN: "11.55486333",
		latitudeE: "7.42202778",
		locationType: "Urban",
		schoolNameType: "Regular",
		sectorType: "Primary"
	},
};

export const schoolNames = [
	{
		name: "Nadabo Primary School",
		key: "nadaboPrimarySchool"

	},
	{
		name: "Ramalan Primary School Bakori",
		key: "ramalanPrimarySchool"

	},
	{
		name: "Gwamutsawa Primary School",
		key: "gwamutsawaPrimarySchool"

	},

]



