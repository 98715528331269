import { Checkbox, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { openDB } from 'idb'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import * as yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../hooks/Redux'
import { BackgroundSyncConstants } from '../../lib/background-sync'
import { setTokens, setUser, setUserRole } from '../../store/features/user/userSlice'
import { useGetUserDetailsMutation, useGetUserRoleMutation, useLoginMutation } from '../../store/services/userApi'
import AuthContainer from './AuthContainer'
import InputField from './FormElements/Input'
import PasswordField from './FormElements/PasswordField'
import SubmitButton from './FormElements/SubmitButton'

const validationSchema = yup.object({
	email: yup.string().email('Enter a valid email').required('Email is required'),
	password: yup.string().required('Password is required'),
})

export default function Login() {
	const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)
	const role = useAppSelector((state) => state.user.role?.data?.data?.name)
	const user = useAppSelector((state) => state.user.user)
	const [login, loginStatus] = useLoginMutation()
	const [getUserDetails, userStatus] = useGetUserDetailsMutation()
	const [userRole, roleStatus] = useGetUserRoleMutation()
	const [isLoading, setIsloading] = useState(false)
	const [firstTimeLogin, setFirstTimeLogin] = useState(false)

	const dispatch = useAppDispatch()
	const toast = useToast()

	const formik = useFormik({
		onSubmit: async (values) => {
			setIsloading(true)
			try {
				const res: any = await login({ email: values.email.trim(), password: values.password.trim() })
				if (res.data) {
					const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION)
					await db.clear(BackgroundSyncConstants.AUTH_DATA_STORE_NAME)
					await db.add(BackgroundSyncConstants.AUTH_DATA_STORE_NAME, {
						access_token: res.data?.data?.access_token,
						refresh_token: res.data?.data?.refresh_token,
						expires: res.data?.data?.expires,
					})
					db.close()

					const userDetailsRes: any = await getUserDetails('')

					if (userDetailsRes?.data) {
						dispatch(setUser(userDetailsRes.data?.data))
						const fetchRole = await userRole(userDetailsRes?.data?.data?.role)
						setFirstTimeLogin(userDetailsRes?.data?.data?.firstTimeLogin)
						dispatch(setUserRole(fetchRole))
					}

					Cookies.set('accessToken', res.data?.data?.access_token)
					Cookies.set('refreshToken', res.data?.data?.refresh_token)

					dispatch(
						setTokens({
							access_token: res.data?.data?.access_token,
							refresh_token: res.data?.data?.refresh_token,
							expires: res.data?.data?.expires,
						})
					)
				} else {
					toast({
						title: 'Error',
						status: 'error',
						description: res?.error?.data?.errors[0]?.message || 'Something went wrong',
						duration: 9000,
						isClosable: true,
					})
				}
				setIsloading(false)
			} catch (error) {
				setIsloading(false)
				toast({
					title: 'Error',
					status: 'error',
					description: 'Something went wrong',
					duration: 9000,
					isClosable: true,
				})
			}
		},
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
	})

	if (isAuthenticated) {
		if (user.firstTimeLogin) {
			return <Navigate to="/change-default-password" />
		}
		if (role === 'Administrator') {
			return <Navigate to="/federal/overview" />
		} else {
			return <Navigate to="/federal/data-entry" />
		}
	}

	//
	return (
		<AuthContainer>
			<div className="relative  w-full  overflow-hidden flex flex-col items-center justify-center text-left text-13xl text-black font-inter">
				<div className="flex-1 rounded-3xl bg-white flex flex-row p-16 items-center justify-center border-[1px] border-solid border-gray-200">
					<div className="self-stretch rounded-xl bg-white w-[100%] max-w-[400px] flex flex-col items-start justify-start gap-[30px]">
						<div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
							<div className="relative font-semibold">Welcome back!</div>
							<h1 className="relative text-base text-gray-600">Welcome back. Please enter your details</h1>
						</div>
						<form
							className="self-stretch flex flex-col items-center justify-start gap-[48px] sm:self-stretch sm:w-auto [&.animate]:sm:animate-[2s_ease_0s_1_normal_forwards_bounce-in-top] "
							method="post"
							onSubmit={formik.handleSubmit}
						>
							<div className="self-stretch flex flex-col items-end justify-start gap-[12px]">
								<div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
									<InputField
										type="email"
										value={formik.values.email}
										name="email"
										label="Email"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={formik.touched.email && Boolean(formik.errors.email)}
										errorMessage={formik.errors.email}
									/>
									<PasswordField
										label="Password"
										name="password"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={formik.touched.password && Boolean(formik.errors.password)}
										errorMessage={formik.errors.password}
										value={formik.values.password}
									/>
								</div>
								<div className="self-stretch flex item flex-row items-start  justify-between gap-[8px]">
									<div className=" flex flex-row items-center justify-start gap-[8px]">
										<Checkbox colorScheme="green" className="text-base font-medium font-inter text-gray-500 text-left">
											Remember me
										</Checkbox>
									</div>
									<div className="self-end relative text-base font-medium font-inter text-success-500 text-left">
										<Link to={'/forgot-password'}>Forgot password?</Link>
									</div>
								</div>
							</div>
							<SubmitButton text="Sign in" isLoading={isLoading} />
						</form>
						{/* <div className="self-stretch flex flex-wrap items-start justify-start gap-[8px] text-base text-gray-500">
							<div className="flex-1 flex flex-row items-center justify-start">
								<div className="relative font-medium">
									Don&apos;t have an account
								</div>
							</div>
							<div className="relative font-medium text-success-500">
								<Link to="/register">Create account</Link>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</AuthContainer>
	)
}
