import './assets/fonts/feather.css'
import './assets/fonts/fontawesome.css'
import './assets/fonts/inter/inter.css'
import './assets/fonts/material.css'
import './assets/fonts/tabler-icons.min.css'

import './assets/css/style-preset.css'
import './assets/css/style.css'

import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import './App.css'
import { triggerBackgroundSync } from './lib/background-sync/triggerBackgroundSync'
import Routes from './routes'

function App() {
	const toast = useToast()
	useEffect(() => {
		const handleOnlineStatusChange = () => {
			if (navigator.onLine) {
				triggerBackgroundSync()
			}
		}

		const channel = new BroadcastChannel('notificationChannel')
		channel.addEventListener('message', (event) => {
			if (event.data && event.data.type === 'push') {
				const notificationData = event.data.payload

				toast({
					status: notificationData.type,
					title: notificationData.type === 'success' ? 'Successful' : 'Error',
					description: `${notificationData.body}`,
					isClosable: true,
					duration: 3000,
				})
			}
		})

		window.addEventListener('online', handleOnlineStatusChange)
		window.addEventListener('offline', handleOnlineStatusChange)

		return () => {
			window.removeEventListener('online', handleOnlineStatusChange)
			window.removeEventListener('offline', handleOnlineStatusChange)
			channel.close()
		}
	}, [toast])

	return <Routes />
}

export default App
