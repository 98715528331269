import { createSlice } from '@reduxjs/toolkit';

interface initialStateProp
{
    adminUsers: any[];
}

const initialState: initialStateProp = {
    adminUsers: [],
};

const adminUserslice = createSlice({
    name: 'adminUser',
    initialState,
    reducers: {
        editAdminUserstate: (state, { payload }) =>
        {
            const filterUsers = state.adminUsers.map((user) =>
            {
                if (user.id === payload.id) {
                    user = payload;
                }
                return user;
            });

            state.adminUsers = filterUsers;
        },
        getAdminUsers: (state, { payload }) =>
        {
            state.adminUsers = payload;
        },
        deleteAdminUserstate: (state, { payload }) =>
        {
            const filter = state.adminUsers.filter((user) => user.id !== payload);
            state.adminUsers = filter;
        },
        addAdminUserstate: (state, { payload }) =>
        {
            state.adminUsers = [ ...state.adminUsers, payload ];
        },
    },
});

export const {
    getAdminUsers,
    deleteAdminUserstate,
    editAdminUserstate,
    addAdminUserstate,
} = adminUserslice.actions;

export default adminUserslice.reducer;
