import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import fakeReducer from './features/fake/fakeSlice';
import fakeUserReducer from './features/fake/fakeUser';
import entriesReducer from './features/entries/entries';
import adminUsersSlice from './features/adminUsers/adminUsersSlice';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { authApi } from './services/userApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import schoolReducer from './features/schools/schoolSlice';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [ 'user', 'fake' ],
};

const rootReducer = combineReducers({
	user: userReducer,
	fake: fakeReducer,
	fakeUser: fakeUserReducer,
	shools: schoolReducer,
	entries: entriesReducer,
	adminUser: adminUsersSlice,
	[ authApi.reducerPath ]: authApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ],
			},
		}).concat(authApi.middleware),
		thunk,
	],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persitor = persistStore(store);

setupListeners(store.dispatch);
