import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { openDB } from 'idb';
import { BackgroundSyncConstants } from '../../lib/background-sync';
import { UserTableFilterData } from '../../pages/users/Users';
import { handleLogout, logoutUser, setTokens } from '../features/user/userSlice';

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_BASE_URL,
	prepareHeaders: async (headers, { endpoint }) =>
	{
		const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

		const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

		const accessToken = data[ 0 ]?.access_token;

		if (accessToken && !endpoint.includes('login') && !endpoint.includes("reset-password")) {
			headers.set('authorization', `Bearer ${accessToken}`);
		}
		db.close();
	},
});

const automaticReauth: BaseQueryFn = async (args, api, extraOptions) =>
{
	let result = await baseQuery(args, api, extraOptions);
	if (result?.error?.status === 401) {
		try {
			const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

			const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

			const refresh_token = data[ 0 ].refresh_token;

			if (refresh_token) {
				const data = await axios.post(`${process.env.REACT_APP_BASE_UR}/auth/refresh`, {
					refresh_token,
					mode: 'json',
				});

				const token_data = {
					...data.data,
					refresh_token,
				};

				api.dispatch(setTokens(token_data));

				await db.clear(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);
				await db.add(BackgroundSyncConstants.AUTH_DATA_STORE_NAME, token_data);
				db.close();
			}
		} catch (error) {
			api.dispatch(logoutUser());
			api.dispatch(handleLogout());
		}
	}
	return result;
};

export const authApi = createApi({
	reducerPath: 'userApi',
	baseQuery: automaticReauth,
	tagTypes: [ 'Auth' ],
	endpoints: (builder) => ({
		login: builder.mutation<any, any>({
			query: (body) => ({
				url: '/auth/login',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: body,
			}),
			invalidatesTags: [ 'Auth' ],
		}),
		updateUser: builder.mutation<any, any>({
			query: (body) => ({
				url: '/users/me',
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: body,
			}),
			invalidatesTags: [ 'Auth' ],
		}),
		// updateUser: builder.mutation<any, any>({
		// 	query: (body) => ({
		// 		url: '/users/me',
		// 		method: 'PATCH',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		body: body,
		// 	}),
		// 	invalidatesTags: [ 'Auth' ],
		// }),
		forgotPasswordRequest: builder.mutation({
			query: (email) => ({
				url: '/auth/password/request',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ email, reset_url: `${process.env.REACT_APP_RESET_URL}/reset-password` }),
			}),
		}),
		firstTimeReset: builder.mutation({
			query: (payload) => ({
				url: `/users/me`,
				method: "PATCH",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					...payload

				})
			})
		}),
		resetPassword: builder.mutation({
			query: (body) => ({
				url: '/auth/password/reset',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
			}),
		}),
		getUserDetails: builder.mutation({
			query: () => ({
				url: '/users/me',
				method: 'GET',
			}),
		}),
		getAccessToken: builder.query({
			query: (payload) => ({
				url: '/auth/refresh',
				method: 'POST',
				body: JSON.stringify({
					mode: 'json',
					refresh_token: payload.refresh_token,
				}),
				headers: { 'Content-type': 'application/json' },
			}),
		}),
		logout: builder.mutation({
			query: async () =>
			{
				const db = await openDB(BackgroundSyncConstants.DB_NAME, BackgroundSyncConstants.DB_VERSION);

				const data = await db.getAll(BackgroundSyncConstants.AUTH_DATA_STORE_NAME);

				const refresh_token = data[ 0 ]?.refresh_token;

				db.close();

				return {
					url: '/auth/logout',
					method: 'POST',
					body: JSON.stringify({
						refresh_token,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				};
			},
		}),
		getUserRole: builder.mutation({
			query: (id) => ({
				url: `/roles/${id}`,
				method: 'GET',
			}),
		}),
		getAllRoles: builder.query({
			query: () => ({
				url: '/roles',
				method: 'GET',
			}),
		}),
		getAllUsersAdmin: builder.mutation({
			query: (payload: UserTableFilterData) => ({
				url: `/users?filter[id][_neq]=${payload.currentUserId}&limit=${payload.pagination.size}&page=${payload.pagination.page}&meta=${payload.meta_type}&${payload.sortBy}&${payload.filterBy}&${payload.searchBy}`,
				method: 'GET',
			}),
		}),
		getAllUsersByState: builder.mutation({
			query: (payload: UserTableFilterData) => ({
				url: `/users?filter[state_id][_eq]=${payload.stateId}&filter[id][_neq]=${payload.currentUserId}&filter[role][_neq]=8979038a-fd2f-4424-be85-54f485978c39&limit=${payload.pagination.size}&page=${payload.pagination.page}&meta=${payload.meta_type}&${payload.sortBy}&${payload.filterBy}&${payload.searchBy}`,
				method: 'GET',
			}),
		}),
		suspendUser: builder.mutation({
			query: (user_id) => ({
				url: `/users/${user_id}`,
				method: 'PATCH',
				body: { status: 'suspended' },
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}),
		activateUser: builder.mutation({
			query: (user_id) => ({
				url: `/users/${user_id}`,
				method: 'PATCH',
				body: { status: 'active' },
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}),
		addOrEditUser: builder.mutation({
			query: (body) => ({
				url: `/users/${body.id ?? ''}`.trim(),
				method: body.id ? 'PATCH' : 'POST',
				body,
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		}),
		getStates: builder.query({
			query: () => ({
				url: '/items/states',
				method: 'GET',
			}),
		}),
		getLGA: builder.query({
			query: () => ({
				url: '/items/lga',
				method: 'GET',
			}),
		}),
		getLGAByState: builder.mutation({
			query: (state_id) => ({
				url: `/items/lga?filter[state_id][_eq]=${state_id}`,
				method: 'GET',
			}),
		}),
		getWardByLga: builder.mutation({
			query: (lga_id) => ({
				url: `/items/ward?filter[lga_id][_eq]=${lga_id}`,
				method: 'GET',
			}),
		}),
		getSchoolsByWard: builder.mutation({
			query: (ward_id) => ({
				url: `/items/school?filter={"ward_id":${ward_id}}`,
				method: 'GET',
			}),
		}),
		submitDataEntry: builder.mutation({
			query: (body) => ({
				url: '/items/entries',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body,
			}),
		}),
		fetchSumTotalTeachers: builder.mutation({
			query: (fields) => ({
				url: `/items/entries?aggregate[sum]=noMaleTeachers,noFemaleTeachers${fields}`,
				method: 'GET',
			}),
		}),
		fetchSumTotalPupils: builder.mutation({
			query: (fields) => ({
				url: `/items/entries?aggregate[sum]=p1Boys,p1Girls,p2Boys,p2Girls,p3Girls,p3Boys,p4Boys,p4Girls,p5Girls,p5Boys,p6Boys,p6Girls${fields}`,
				method: 'GET',
			}),
		}),
		fetchSumTotalBoys: builder.mutation({
			query: (fields) => ({
				url: `/items/entries?aggregate[sum]=p1Boys,p2Boys,p3Boys,p4Boys,p5Boys,p6Boys${fields}`,
				method: 'GET',
			}),
		}),
		fetchSumTotalGirls: builder.mutation({
			query: (fields) => ({
				url: `/items/entries?aggregate[sum]=p1Girls,p2Girls,p3Girls,p4Girls,p5Girls,p6Girls${fields}`,
				method: 'GET',
			}),
		}),
		fetchSumTotalSchools: builder.mutation({
			query: (fields) => ({
				url: `/items/school?aggregate[count]=*${fields}`,
				method: 'GET',
			}),
		}),
		fetchPupilsAndTeachers: builder.mutation({
			query: (fields) => ({
				url: `/items/entries?${fields}`,
				method: 'GET',
			}),
		}),
		fetchAllSchoolAdmin: builder.mutation({
			query: (fields) => ({
				url: `/items/school?${fields}`,
				method: 'GET',
			}),
		}),
		createSchool: builder.mutation({
			query: (body) => ({
				url: '/items/school',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body,
			}),
		}),
		editSchool: builder.mutation({
			query: (data) => ({
				url: `/items/school/${data.id}`,
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: data?.body,
			}),
		}),
		deleteSchool: builder.mutation({
			query: (id) => ({
				url: `/items/school/${id}`,
				method: 'DELETE',
			}),
		}),
		fetchSingleSchool: builder.mutation({
			query: (fields) => ({
				url: `/items/school/${fields}`,
				method: 'GET',
			}),
		}),
		fetchAllEntriesSum: builder.mutation({
			query: (fields) => ({
				url: `/items/entries?${fields}`,
				method: 'GET',
			}),
		}),
		updateEntries: builder.mutation({
			query: (payload) => ({
				url: `/items/entries/${payload.id}`,
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: payload?.body,
			}),
		}),
		fetchEntriesById: builder.query({
			query: (id) => ({
				url: `/items/entries/${id}?fields=*,school_id.name,school_id.id`,
				method: 'GET',
			}),
		}),
		createMultipleScchool: builder.mutation({
			query: (body) => ({
				url: '/items/school',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body,
			}),
		}),
	}),
});

export const {

	useGetAllRolesQuery,
	useGetLGAQuery,
	useGetStatesQuery,
	useLoginMutation,
	useLogoutMutation,
	useFetchEntriesByIdQuery,
	useGetAllUsersAdminMutation,
	useGetUserRoleMutation,
	useAddOrEditUserMutation,
	useGetLGAByStateMutation,
	useGetWardByLgaMutation,
	useGetSchoolsByWardMutation,
	useGetAllUsersByStateMutation,
	useSubmitDataEntryMutation,
	useSuspendUserMutation,
	useActivateUserMutation,
	useForgotPasswordRequestMutation,
	useResetPasswordMutation,
	useFetchSumTotalTeachersMutation,
	useFetchSumTotalPupilsMutation,
	useFetchSumTotalSchoolsMutation,
	useFetchAllSchoolAdminMutation,
	useDeleteSchoolMutation,
	useCreateSchoolMutation,
	useEditSchoolMutation,
	useFetchSingleSchoolMutation,
	useFetchAllEntriesSumMutation,
	useUpdateEntriesMutation,
	useCreateMultipleScchoolMutation,
	useUpdateUserMutation,
	useGetUserDetailsMutation,
	useFirstTimeResetMutation,
	useFetchSumTotalBoysMutation,
	useFetchSumTotalGirlsMutation
} = authApi;
