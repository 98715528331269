import { Button } from '@chakra-ui/react'

interface Props {
	text: string
	isLoading?: boolean
}
const SubmitButton = ({ text, isLoading }: Props) => {
	return (
		<Button
			isLoading={isLoading}
			type="submit"
			variant="solid"
			colorScheme="green"
			className="self-stretch font-medium rounded-full bg-success-500 flex flex-row py-2.5 px-3 items-center justify-center text-base text-white"
		>
			{text}
		</Button>
	)
}

export default SubmitButton
