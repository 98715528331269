import React from 'react'
import { Circles } from 'react-loader-spinner'

const Loader = () => {
	return (
		<div className="w-full grid place-items-center h-[450px]">
			<Circles
				height="80"
				width="80"
				color="#4fa94d"
				ariaLabel="circles-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
		</div>
	)
}

export default Loader
