import { createSlice } from '@reduxjs/toolkit'
import { userTable } from '../../../DUMMYDATA'

interface initialStateProps {
	userData: any[]
}

const initialState: initialStateProps = {
	userData: [],
}

const fakeUser = createSlice({
	name: 'fakeUser',
	initialState,
	reducers: {
		inviteUser: (state, action) => {
			const getUserFromDb = JSON.parse(localStorage.getItem('userData') as string)

			const avoidDuplicateUser: any[] = getUserFromDb.filter((user: any) => user.email !== action.payload.emal)
			avoidDuplicateUser.push(action.payload)
			localStorage.setItem('userData', JSON.stringify(avoidDuplicateUser))
			state.userData = avoidDuplicateUser as any
		},
		suspendUser: (state, action) => {
			const findUser: any = state.userData.find((user: any) => user.id === action.payload)
			if (findUser) {
				findUser.status = 'suspended'
				const filterOutUser = state.userData.filter((user: any) => user.id !== action.payload)

				filterOutUser.push(findUser)
				localStorage.setItem('userData', JSON.stringify(filterOutUser))
				state.userData = filterOutUser
			}
		},
		addAll: (state) => {
			const checkStorage = JSON.parse(localStorage.getItem('userData') as string)
			if (!checkStorage) {
				localStorage.setItem('userData', JSON.stringify(userTable))
				state.userData = userTable
			} else {
				state.userData = JSON.parse(localStorage.getItem('userData') as string)
			}
		},
	},
})

export const { inviteUser, suspendUser, addAll } = fakeUser.actions

export default fakeUser.reducer
