type Props = { children: any }

export default function AuthContainer({ children }: Props) {
	return (
		<div className="auth-main">
			<div className="auth-wrapper v1">
				<div className="auth-form">{children}</div>
			</div>
		</div>
	)
}
