import React, { Suspense } from 'react'
import { Route, Routes as Switch } from 'react-router'
import Login from '../components/Authentication/Login'
import NotAuthorized from '../components/Errors/NotAuthorized'
import PageNotFound from '../components/Errors/PageNotFound'
import Loader from '../components/Loader/Loader'
import ChangeDefaultPassword from '../components/Authentication/ChangeDefaultPassword'

const Register = React.lazy(() => import('../components/Authentication/Register'))
const ForgotPassword = React.lazy(() => import('../components/Authentication/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../components/Authentication/ResetPassword'))
const ConfirmMail = React.lazy(() => import('../components/Authentication/CheckEmail'))

const FederalRoutes = React.lazy(() => import('../components/ProtectedRoutes/ProtectedRoute'))

const Routes = () => {
	return (
		<Switch>
			<Route path="/" element={null}>
				<Route index element={<Login />} />
				<Route
					path="register"
					element={
						<Suspense fallback={<Loader />}>
							<Register />
						</Suspense>
					}
				/>
				<Route
					path="/forgot-password"
					element={
						<Suspense fallback={<Loader />}>
							<ForgotPassword />
						</Suspense>
					}
				/>
				<Route
					path="/change-default-password"
					element={
						<Suspense fallback={<Loader />}>
							<ChangeDefaultPassword />
						</Suspense>
					}
				/>
				<Route
					path="/confirm-mail"
					element={
						<Suspense fallback={<Loader />}>
							<ConfirmMail />
						</Suspense>
					}
				/>
				<Route
					path="/reset-password"
					element={
						<Suspense fallback={<Loader />}>
							<ResetPassword />
						</Suspense>
					}
				/>
			</Route>
			<Route
				path="/federal/*"
				element={
					<Suspense fallback={<Loader />}>
						<FederalRoutes />
					</Suspense>
				}
			/>
			<Route path="/not-authorized" element={<NotAuthorized />} />
			<Route path="*" element={<PageNotFound />} />
		</Switch>
	)
}

export default Routes
