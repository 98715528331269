import React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { store, persitor } from '../store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ApolloProvider } from '@apollo/client'
import client from '../config/graphql.config'

export default function AllProviders({ children }: any) {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persitor}>
				{/* <ApolloProvider client={client}> */}
				<ChakraProvider>{children}</ChakraProvider>
				{/* </ApolloProvider> */}
			</PersistGate>
		</Provider>
	)
}
