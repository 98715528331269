import { createSlice } from '@reduxjs/toolkit';

interface initialStateProp
{
    entries: any[];
}

const initialState: initialStateProp = {
    entries: [],
};

const entriesSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        approveEntries: (state, { payload }) =>
        {
            const filterSchool = state.entries.map((school) =>
            {
                if (school.id === payload.id) {
                    school = payload;
                }
                return school;
            });

            state.entries = filterSchool;
        },
        getEntries: (state, { payload }) =>
        {
            state.entries = payload;
        },
        deleteentriestate: (state, { payload }) =>
        {
            const filter = state.entries.filter((school) => school.id !== payload);
            state.entries = filter;
        },
        addEntries: (state, { payload }) =>
        {
            state.entries = [ ...state.entries, payload ];
        },
    },
});

export const {
    getEntries,
    deleteentriestate,
    approveEntries,
    addEntries,
} = entriesSlice.actions;

export default entriesSlice.reducer;
